import React from "react";

const RemoveBtn = ({ start, end, decrease }) => (
    <span
        onTouchStart={() => start(false)}
        onMouseUp={end}
        onTouchEnd={end}
        onTouchCancel={end}
        onMouseDown={() => start(false)}
        onClick={decrease}
        className="material-icons shdow-1 rounded-full p-1 text-5xl orange-text fade-svg-btn">
        remove
    </span>
);

export default RemoveBtn;
