import React from "react";

const DetailsLabel = ({ label, bottom = 35 }) => {
    return (
        <p className="font-bold shdow-1 text-3xl py-3 carbonic text-center backdrop-blur-lg blur-card-1 absolute z-3" style={{ left: 0, right: 0, bottom }}>
            <span className="text-lg font-light carbonic">المواد:</span>
            <span>&#160;{label.quantity}</span>
            <span>&#160;/&#160;</span>
            <span className="text-lg font-light carbonic">المجموع: </span>
            <span>&#160;{label.totalAmount}</span>
        </p>
    );
};

export default DetailsLabel;
