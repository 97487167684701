import { API } from "./API";

let auth_token = "1430c38f109946cd8c283dce1375555cc40ae818";
const Uploader = {
    uploadImage: function (files, onComplate) {
        let formData = new FormData();
        formData.append("source", files[0]);
        formData.append("type", "file");
        formData.append("action", "upload");
        formData.append("timestamp", Date.now());
        formData.append("auth_token", auth_token);

        // console.log(formData);
        // return;
        API.post("", formData)
            .then(({ data }) => {
                // console.log(data.image.display_url);
                // console.log(data.image.image.url);
                // console.log(data.image.medium.url);
                // console.log(data.image.thumb.url);
                // setData([data.image.image.url, data.image.medium.url, data.image.thumb.url, data.image.display_url]);
                onComplate(data.image.display_url);
            })
            .catch((err) => {
                console.log(err);
            });
    },
};
export default Uploader;
