// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBcMJaJaGINgrUpzX6s7zGJjgQ2qG13haY",
    authDomain: "marketcaptain-27954.firebaseapp.com",
    databaseURL: "https://marketcaptain-27954-default-rtdb.firebaseio.com",
    projectId: "marketcaptain-27954",
    storageBucket: "marketcaptain-27954.appspot.com",
    messagingSenderId: "178667468654",
    appId: "1:178667468654:web:2da5289cb09a98ce6cf37f",
    measurementId: "G-QLR7L956CC",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const db = getDatabase();
export const fdb = getFirestore();
