import { uid } from "uid";
import Items from "../../Api/Items";
import React, { useState } from "react";
import Uploader from "../../Api/Upolder";
import Loader from "../../Components/Loader";
import PopupContainer from "../../Containers/PopupContainer";
import TextInput from "../../Components/TextInput";
import Formatter from "../../Utils/Formatter";
import Language from "../../Utils/Languages";
import Form from "../../Containers/Form";

const AddItemsCard = ({ onComplate, myItems, setMyItems }) => {
    const [name, setName] = useState("");
    const [price, setPrice] = useState("");
    const [loading, setLoading] = useState(false);
    const [files, setFiles] = useState("");
    const [imageUrl, setImageUrl] = useState(false);

    const hiddenFileInput = React.useRef(null);

    const addItem = async (image) => {
        setLoading(true);
        let itemToAdd = {
            id: uid(),
            name,
            image,
            price,
        };
        myItems = [itemToAdd, ...myItems];
        await Items.AddItem(myItems);
        myItems.find((item) => item.id === itemToAdd.id).currencyPrice = Formatter(itemToAdd.price, 1);
        setName("");
        setPrice("");
        setMyItems(myItems);
        onComplate();
    };

    const uploadImage = () => {
        setLoading(true);
        if (files.length > 0) Uploader.uploadImage(files, addItem);
        else addItem("");
    };

    return loading ? (
        <Loader />
    ) : (
        <PopupContainer close={onComplate}>
            <Form submitText="Save" onSubmit={uploadImage}>
                <TextInput placeholder="ItemName" value={name} setValue={setName} required autoFocus />
                <TextInput placeholder="ItemPrice" value={price} setValue={setPrice} required type="number" />
                <div className="col">
                    <div onClick={() => hiddenFileInput.current.click()} className="row-center px-4 shdow-1 rounded-3 mx-2 my-4">
                        <img
                            src="https://www.pngfind.com/pngs/m/128-1280885_image-icon-gallery-icon-png-circle-transparent-png.png"
                            alt="imageer"
                            style={{ height: 20 }}
                            className="rounded-1"
                        />
                        <p className="carbonic font-bold px-4">{Language.ItemImage}</p>
                    </div>
                    {imageUrl && <img src={imageUrl} alt="profile" style={{ maxWidth: "94%", margin: "3%" }} className="rounded-1" />}
                    <input
                        type="file"
                        accept="image/*"
                        className="hidden"
                        ref={hiddenFileInput}
                        onChange={(e) => {
                            setFiles(e.target.files);
                            setImageUrl(URL.createObjectURL(e.target.files[0]));
                        }}
                    />
                </div>
            </Form>
        </PopupContainer>
    );
};

export default AddItemsCard;
