import FirebaseControllers from "../Utils/FirebaseControllers";

export const Bills = {
    SendBill: async (bill) => {
        await FirebaseControllers.SetObject("bills", bill);
    },
    GetBill: async () => {
        let billId = (JSON.parse(localStorage.getItem("user")) || { phoneNumber: "" }).phoneNumber;
        return await FirebaseControllers.GetData("bills", billId);
    },
};
