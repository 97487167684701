import { Link } from "react-router-dom";
import Scroller from "../Containers/Scroller";
import { GetCategories } from "../Api/Items";
import React, { useEffect, useState } from "react";
import InfoLabel from "../Components/InfoLabel";
import useLocalStorage from "@rehooks/local-storage";
import { DefaultImage } from "../Utils/Languages";

const CategoriesView = () => {
    const [categories, setCategories] = useState([]);
    const [loading, setLoading] = useState(true);
    const [user] = useLocalStorage("user");

    let isFavorite = window.location.pathname.endsWith("favorites");
    const fetchData = async () => {
        let _categories = await GetCategories(isFavorite);
        setLoading(false);
        setCategories(_categories);
    };
    useEffect(() => {
        fetchData();
    }, [isFavorite]);
    return (
        <Scroller loading={loading} className="larg-grid">
            <div className="row-center gap-6">
                <Link to={`/Items/${user.resever}`} className="rounded-3 flex-1 cyan-card" style={{ textDecoration: "none" }}>
                    <p className="font-bold text-xl text-center white-text">موادي الخاصة</p>
                </Link>
                {categories.length > 0 ? (
                    <Link to={`/Items${isFavorite ? "/favorites" : ""}`} className="rounded-3 flex-1 red-card" style={{ textDecoration: "none" }}>
                        <p className="font-bold white-text text-xl text-center carbonic">أضهار بدون تصنيفات</p>
                    </Link>
                ) : (
                    <InfoLabel title="لاتوجد مواد مفضلة" />
                )}
            </div>
            <p className="font-bold border-bottom border-top p-3 rounded-3 carbonic text-center">
                <span className="text-md"> رمز الطلبات</span>
                &#160;&#160;:&#160; &#160;
                <span className="text-3xl">{user.resever}</span>
            </p>
            {categories.map((category) => (
                <Link
                    to={`/Items/${category.id}${isFavorite ? "/favorites" : ""}`}
                    className="categories-card row-center"
                    key={category.id}
                    style={{ textDecoration: "none" }}>
                    <div style={{ width: "50%", marginLeft: "auto" }} className="">
                        {category.quantity ? (
                            <div className="col-center">
                                <p className="orange-card px-3 white-text text-2xl rounded-2" style={{ height: 24 }}>
                                    {category.totalAmount}
                                </p>
                                <div className="row-center">
                                    <p className="red-card mx-2 white-text text-2xl text-center rounded-2 px-3" style={{ height: 22 }}>
                                        {category.quantity}
                                    </p>
                                    <p className="carbonic">{category.name}</p>
                                </div>
                            </div>
                        ) : (
                            <p className="carbonic text-center">{category.name}</p>
                        )}
                    </div>
                    <img src={category.image || DefaultImage} alt={category.name} />
                </Link>
            ))}
        </Scroller>
    );
};

export default CategoriesView;
