import React from "react";
import SubmitButton from "../Components/SubmitButton";
import Language from "../Utils/Languages";

const Form = ({ children, onSubmit, submitText = "Confirm", className, cancle }) => {
    const _onSubmit = (e) => {
        e.preventDefault();
        onSubmit(e);
        return false;
    };

    return (
        <form className={`col carbonic-card p-4 rounded-2 ${className}`} onSubmit={_onSubmit}>
            {children}
            {cancle ? (
                <div className="row">
                    <SubmitButton text={submitText} />
                    <input
                        onClick={cancle}
                        type="button"
                        value={Language.Cancel}
                        className="font-bold pointer shadow-1 carbonic-card-1 carbonic rounded-2 px-10 py-3 my-2 mx-auto"
                    />
                </div>
            ) : (
                <SubmitButton text={submitText} />
            )}
        </form>
    );
};

export default Form;
