import React from "react";
import { Link } from "react-router-dom";
import Button from "../Components/Button";
import Scroller from "../Containers/Scroller";

const HomeView = () => {
    return (
        <Scroller className="col justify-around h-full">
            <div className="col-center justify-center my-10 flex-grow carbonic-card rounded-3 shadow-1">
                <p className="font-light pointer carbonic text-center text-4xl"> جيبـــلـنة ويــــاك </p>
                <p className="font-light pointer carbonic text-center text-4xl"> تدللون </p>
            </div>
            <Link to="/categories" style={{ textDecorationLine: "unset" }}>
                <Button onClick={() => {}} color="cyan" title="تسوق" />
            </Link>
            <Button
                extraClass="absolute bottom-2 left-0 right-0"
                onClick={() => {
                    localStorage.clear();
                    window.location.reload();
                }}
                color="red"
                title="حذف الحساب"
            />
        </Scroller>
    );
};

export default HomeView;
