import React from "react";
import HomeView from "../Home/HomeView";
import ItemsView from "../Items/ItemsView";
import { Routes, Route } from "react-router-dom";
import BillsView from "../Bills/BillsView";
import BottomBar from "../Navigations/BottomBar";
import CategoriesView from "../Categories/CategoriesView";
import FavortiesItemsView from "../Items/FavortiesItemsView";
import Shimaa from "./Shimaa";

const AuthRoutes = () => {
    return (
        <div>
            <Routes>
                <Route path="/" element={<HomeView />} />
                <Route path="/shimaa" element={<Shimaa />} />
                <Route path="/categories" element={<CategoriesView />} />
                <Route path="/categories/favorites" element={<CategoriesView />} />
                <Route path="/Items" element={<ItemsView />} />
                <Route path="/Items/favorites" element={<FavortiesItemsView />} />
                <Route path="/Items/:categoryId" element={<ItemsView />} />
                <Route path="/Items/:categoryId/favorites" element={<FavortiesItemsView />} />
                <Route path="/bills" element={<BillsView />} />
            </Routes>
            <BottomBar />
        </div>
    );
};

export default AuthRoutes;
