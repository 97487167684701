import React from "react";
import CounterForm from "../../Components/CounterForm";
import Formatter from "../../Utils/Formatter";
import { DefaultImage } from "../../Utils/Languages";

const ItemCard = ({ item, update }) => {
    function changeQuantity(value) {
        item.quantity = value;
        update(item);
    }
    function handleFavorite() {
        item.favorite = !item.favorite;
        update(item);
    }
    return (
        <div className={`item-card items-start relative carbonic-card `}>
            <div className="flex-grow row">
                <img className="resever" src={item.image || DefaultImage} alt={item.name} />
                <p className="carbonic px-4 text-2xl">
                    <span>{item.name}</span>
                    <br />
                    <br />
                    <span> {Formatter(item.price, 1)} </span>
                    <br />
                </p>
                <p className={`text-4xl ${item.favorite ? "green-text" : "carbonic"}`} style={{ marginRight: "auto" }}>
                    <span className="font-bold text-md">العدد</span>&nbsp;&nbsp;
                    {item.quantity}
                </p>
            </div>
        </div>
    );
};

export default ItemCard;
