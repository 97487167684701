import React from "react";
import Form from "../Containers/Form";
import PopupContainer from "../Containers/PopupContainer";
import TextInput from "./TextInput";

const PopupInput = ({ onSubmit, close, formClass, containerClass, type = "number" }) => {
    const handleSubmit = () => {
        onSubmit(value);
    };
    const [value, setValue] = React.useState("");
    return (
        <PopupContainer close={close} className={containerClass}>
            <Form cancle={close} onSubmit={handleSubmit} className={formClass}>
                <TextInput type={type} value={value} setValue={setValue} autoFocus />
            </Form>
        </PopupContainer>
    );
};

export default PopupInput;
