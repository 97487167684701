import React from "react";
import { Link } from "react-router-dom";

const Tab = ({ route, active }) => {
    return (
        <Link to={route.path} style={{ textDecoration: "none" }}>
            <p className={`font-light text-xl  ${active ? "red-text text-2xl px-10" : "carbonic"}`}>{route.title}</p>
        </Link>
    );
};

export default Tab;
